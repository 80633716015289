
















































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Settings extends Vue {
  menuItems: {
    label: string,
    icon: string,
    path: string,
  }[] = [
    {
      label: 'Sources',
      icon: 'mdi-database',
      path: 'sources',
    },
    {
      label: 'Users',
      icon: 'mdi-account-multiple',
      path: 'users',
    },
  ]
}
